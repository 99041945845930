<template>
  <app-layout>
    <page-header> Cereri </page-header>
    <page-section>
      <loader v-if="loading" class="m-5" />
      <template v-else>
        <template v-if="totalRows">
          <rfq-items-index-list-item
            v-for="item in rows"
            :key="item.id"
            :item="item"
            class="mb-4"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </template>
        <no-results
          v-else
          class="m-5"
          message="Nici un rezultat gasit. Asigurati-va ca ati adaugat masini pentru care sa primiti notificari."
        />
      </template>
    </page-section>
  </app-layout>
</template>

<script>
import rfqItemsApi from "@/api/resources/rfqItemsApi";
import AppLayout from "@/layout/AppLayout.vue";
import Loader from "@/components/Loader.vue";
import Pagination from "@/components/Pagination.vue";
import NoResults from "@/components/NoResults.vue";
import RfqItemsIndexListItem from "../components/RfqItemsIndexListItem.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";

export default {
  components: {
    AppLayout,
    Loader,
    Pagination,
    NoResults,
    RfqItemsIndexListItem,
    PageHeader,
    PageSection,
  },

  data() {
    return {
      row: [],
      meta: {},
      links: {},
      loading: false,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;
      rfqItemsApi.index(page && { page }).then((data) => {
        this.rows = data.data;
        this.meta = data.meta;
        this.links = data.links;
        this.loading = false;
        this.scrollTop();
      });
    },
  },

  created() {
    this.index();
  },
};
</script>

<style></style>
