<template>
  <v-card>
    <v-card-header>
      <v-card-title> Piesa: {{ item.name }} </v-card-title>
      <template #actions>
        <router-link
          :to="{
            name: 'quotations.index',
            params: { id: item.id },
          }"
          class="btn-primary"
        >
          Cotatiile mele
        </router-link>
      </template>
    </v-card-header>
    <v-card-body>
      <v-card-body-title> Masina </v-card-body-title>
      <ul class="list-disc list-inside text-sm text-gray-500">
        <li>
          Marca:
          <span class="ml-1"> {{ item.car.make.name }}</span>
        </li>
        <li>
          Model:
          <span class="ml-1"> {{ item.car.name }}</span>
        </li>
        <li>
          An:
          <span class="ml-1"> {{ item.car.year }}</span>
        </li>
        <li>
          Combustibil:
          <span class="ml-1"> {{ item.car.fuel_type.name }}</span>
        </li>
        <li>
          Tip caroserie:
          <span class="ml-1"> {{ item.car.style.name }}</span>
        </li>
        <li>
          Serie sasiu:
          <span class="ml-1"> {{ item.car.chassis_number }}</span>
        </li>
      </ul>

      <v-card-body-title class="mt-4"> Aditionale </v-card-body-title>
      <ul class="flex flex-row space-x-6 mt-1 text-sm text-gray-500">
        <li>Poze: {{ item.pictures ? "da" : "nu" }}</li>
        <li>Garantie: {{ item.warranty ? "da" : "nu" }}</li>
        <li>Posibilitate de retur: {{ item.return ? "da" : "nu" }}</li>
        <li>Cantitate: {{ item.quantity }}</li>
      </ul>

      <v-card-body-title class="mt-4"> Alte detalii </v-card-body-title>
      <p clas="text-sm text-gray-500">
        {{ item.notes }}
      </p>
    </v-card-body>
  </v-card>
</template>

<script>
import VCard from "./VCard.vue";
import VCardBody from "./VCardBody.vue";
import VCardBodyTitle from "./VCardBodyTitle.vue";
import VCardHeader from "./VCardHeader.vue";
import VCardTitle from "./VCardTitle.vue";

export default {
  components: {
    VCard,
    VCardHeader,
    VCardBody,
    VCardTitle,
    VCardBodyTitle,
  },
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style></style>
